<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>

  </v-app>
</template>

<style lang="scss">
@use '@/styles/variables' as v;

.v-text-field {
  input {
    color: v.$input-primary !important;
  }
  input[type=text]:focus {
    color: v.$text-body !important;
  }
}

.v-field.v-field--disabled {
  background-color: #EFF0F2;
  opacity: 1;
}

.v-label {
  font-weight: 500;
}

.label {
  font-weight: 500;
}

</style>

<script setup>

</script>
