/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'

// Components
import App from './App.vue'
import VueSlider from "vue-3-slider-component"

// Composables
import { createApp } from 'vue'

const app = createApp(App)

registerPlugins(app)

app.component('VueSlider', VueSlider)
app.mount('#app')
